<template>
  <!--  苹果app下载-->
  <div ref="bigshow" class="box">
    <div ref="content">
      <div ref="logo">
        <img class="logoImg" src="../../assets/XunJianGo_Logo1.png" />
      </div>
      <div ref="slogan" class="slogan">
        <p>
          <span style="color: #000000">极简</span>
          <span style="color: #d31b16">拍照</span>
          <span style="color: #000000">记录</span>
        </p>
        <p>
          <span style="color: #d31b16">一键</span>
          <span style="color: #000000">生成</span>
          <span style="color: #d31b16">巡检报告</span>
        </p>
      </div>

      <div ref="download" class="buttons download" @click="AppDownLoad">
        <a
          :href="appEntity.Url"
          target="_blank"
          style="color: #fff; text-decoration: none"
          >去AppStore下载</a
        >
      </div>
      <div ref="devInfo" class="devInfo">
        <p><span class="">开发者:&nbsp;&nbsp;筑赢台智慧建筑</span></p>
        <span class="">版本号:&nbsp;&nbsp;{{ VersionNumber }}</span>
      </div>
      <div ref="footer" style="text-align: center">
        <router-link
          :to="{ path: '/NoT/Article' }"
          style="font-size: 14px; color: #d1100b; text-decoration: none"
          target="_blank"
          >服务协议</router-link
        >
        <el-divider direction="vertical"></el-divider>
        <router-link
          :to="{ path: '/NoT/Privacys' }"
          style="font-size: 14px; color: #d1100b; text-decoration: none"
          target="_blank"
          >隐私政策</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "XunJianGo",
  data() {
    return {
      appEntity: {},
      href: "",
      openType: "",
      VersionNumber: "1.1.6",
      screenWidth: "",
      screenHeight: "",
    };
  },
  mounted() {
    // this.$nextTick(() => {
    //   this.getOpenType();
    //   this.AppLoading();
    // });
    this.screenWidth = document.body.clientWidth; //监听页面缩放
    this.screenHeight = document.body.clientHeight;
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
        this.screenHeight = document.body.clientHeight;
      })();
    };
  },
  watch: {
    screenWidth() {
      this.$refs.bigshow.style.width = this.screenWidth + "px"; //浏览器窗口变化时，改变宽度。
      this.$refs.content.style.width = this.screenWidth + "px"; //设定内容区的宽度
      this.$refs.content.style.textAlign = "center";
      this.$refs.logo.style.margin = "0 auto";
      this.$refs.logo.style.padding = "50px 0 0 0";
      this.$refs.logo.style.textAlign = "center";
      this.$refs.slogan.style.width = this.screenWidth + "px";
    },
    screenHeight() {
      this.$refs.bigshow.style.height = this.screenHeight + "px"; //浏览器窗口变化时，改变高度。
      this.$refs.content.style.height = this.screenHeight * 0.7 + "px"; //设定内容区的高度
      // this.$refs.content.style.marginTop = this.screenHeight * 0.1 + "px"; //设定内容区距离顶端为4分之一的屏幕高度
      this.$refs.slogan.style.height = this.screenHeight * 0.2 + "px";
      this.$refs.slogan.style.marginTop = this.screenHeight * 0.12 + "px";
    },
  },
  created() {
    let openType = "";
    var u = navigator.userAgent;
    let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
    let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    let isWechat = u.toLowerCase().indexOf("micromessenger") != -1; //微信浏览器
    console.log("isAndroid", isAndroid);
    console.log("isiOS", isiOS);
    console.log("isWechat", isWechat);

    if (isiOS) {
      this.openType = "ios";
    }
    if (isAndroid) {
      this.openType = "android";
      this.$message.error("请使用IOS手机扫码!");
      return;
    }
    if (isWechat) {
      this.openType = "wechat";
    }
    console.log("判断手机类型结果", u, this.openType);
    this.$nextTick(() => {
      this.getAppDownLoadURL(this.openType);
    });
  },
  methods: {
    //获取APP下载路径
    getAppDownLoadURL(type) {
      this.$http
        .post("/ApkEdition/Apk_Edition/GetApkEdtionData?AppType=ios")
        .then((res) => {
          if (res.Success) {
            this.appEntity = res.Data;
            this.VersionNumber = res.Data.VersionNumber; //当前APP的版本号
            window.open(res.Data.Url, "_self"); //进来及时打开跳转弹窗提示appStore
          } else {
            this.$message.error(res.Msg);
          }
        });
    },
    // 根据当前打开系统环境
    getOpenType() {
      const u = navigator.userAgent;
      if (u.toLowerCase().match(/MicroMessenger/i) == "micromessenger") {
        this.openType = "WeiXin";
      } else if (!!u.match(/compatible/i) || u.match(/Windows/i)) {
        this.openType = "Windows";
      } else if (!!u.match(/Macintosh/i) || u.match(/MacIntel/i)) {
        this.openType = "MacOS";
      } else if (!!u.match(/iphone/i) || u.match(/Ipad/i)) {
        this.openType = "IOS";
      } else if (u.match(/android/i)) {
        this.openType = "Android";
      } else {
        this.openType = "Unknown";
      }
    },
    //判断系统类型
    AppLoading() {
      if (this.openType === "Android") {
        this.getAppDownLoadURL("Android");
      }
      if (this.openType === "IOS") {
        this.getAppDownLoadURL("IOS");
      }
    },
    // 下载按钮点击事件
    AppDownLoad() {
      if (this.openType === "WeiXin") {
        this.$message.success("请使用浏览器打开当前页面!");
      }
      if (this.openType === "Windows") {
        this.$message.success("请使用移动端浏览器打开当前页面!");
      }
      if (this.openType === "MacOS") {
        this.$message.success("请使用移动端浏览器打开当前页面!");
      }
      if (this.openType === "android") {
        this.$message.error("请使用IOS手机扫码!");
        return;
      }
      if (this.openType === "IOS") {
        this.$message.success("当前无IOS客户端");
      }
      if (this.openType === "Unknown") {
        this.$message.success("无法识别当前打开页面系统");
      }
    },
    // 模拟超链接进行文件下载操作
    downloadFile(filename, href) {
      if ("download" in document.createElement("a")) {
        const elink = document.createElement("a");
        elink.download = filename;
        elink.style.display = "none";
        elink.href = href;
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href);
        document.body.removeChild(elink);
      }
    },
  },
};
</script>

<style scoped>
.box {
  background-image: url(../../assets/AppBGP.webp);
  background-size: cover;
}

.logoImg {
  width: 88px;
  height: 104px;
}

.slogan {
  font-size: 26px;
  font-family: PingFangSC, serif;
  font-weight: bold;
  letter-spacing: 10px;
}

.buttons {
  border: 1px solid #d31b16;
  font-family: PingFangSC, serif;
  font-weight: bold;
  font-size: 15px;
  letter-spacing: 2px;
  width: 183px;
  height: 44px;
  line-height: 44px;
  border-radius: 0;
  text-align: center;
  text-decoration: none;
  margin: 15px auto;
  border-radius: 10px;
  padding: 0 30px;
}

.download {
  color: white !important;
  background-color: #d31b16 !important;
}

.openApp {
  color: #d31b16 !important;
  background-color: #ffffff !important;
}

.devInfo {
  margin: 25px auto;
  text-align: center;
  color: #a7a7a7;
  font-size: 10px;
  font-family: "PingFangSC-Regular", serif;
}
</style>
